import { ListFilter } from '../list-filter.interface';
import { FilterType } from '../filter-item.interface';

export class AutoCompleterFilter extends ListFilter {
  type = FilterType.AutoCompleter;


  filterItem(valueItem: any): boolean {
    if (this.isValueInvalid()) {
      // filter is off;
      return true;
    }

    if (!(this.field in valueItem)) {
      console.warn(`${this.field} is not presented in the filtered item`);
      return false
    }

    // if multiple check the value length because its an array
    if (!this.value || (this.options.multiple ? !this.value.length : false)) {
      //values are not selected yet
      return true;
    }
    //do the actual filtering
    if (this.options?.filterFn) {
      return this.options.filterFn(this.value, valueItem);
    } else {
      if (this.options.multiple) {
        return Boolean(this.value.find((item: { id: number }) => item.id === (valueItem[this.field])));
      } else {
        //autocomplete with single select
        return this.value === valueItem[this.field];
      }
    }
  }

  filter(values: any[]): any[] {
    if (this.isValueInvalid()) {
      // filter is off;
      return values;
    }

    return values.filter((item: any) => this.filterItem(item))
  }

  isValueInvalid(): boolean {
    // in case of multiple activated we should look for values to be array with at least one item in other cases, just to have a value
    return this.value === null || this.value === undefined || (this.options.multiple ? !this.value.length : false);
  }


}
