import { ListFilter } from '../list-filter.interface';
import { FilterType } from '../filter-item.interface';

export class BooleanFilter extends ListFilter {
  type = FilterType.Boolean;


  filterItem(valueItem: any): boolean {
    return valueItem[this.field] === Boolean(this.value);
  }

  filter(values: any[] = []): any[] {
    return values.filter(item => this.filterItem(item));
  }

}
