import { ListFilter } from '../list-filter.interface';
import { FilterType } from '../filter-item.interface';

export class DropdownFilter extends ListFilter {
  type = FilterType.Dropdown;


  filterItem(valueItem: any): boolean {
    if (this.isValueInvalid()) {
      // filter is off;
      return true;
    }

    if (!(this.field in valueItem)) {
      console.warn(`${this.field} is not presented in the filtered item`);
      return false
    }
    //do the actual filtering
    if (this.options?.filterFn) {
      return this.options.filterFn(this.value, valueItem);
    } else {
      if (this.options.multiple) {
        return this.value.includes(valueItem[this.field]);
      } else {
        return this.value === valueItem[this.field];
      }
    }

  }

  filter(values: any[]): any[] {
    if (this.isValueInvalid()) {
      // filter is off;
      return values;
    }

    return values.filter((item: any) => this.filterItem(item))
  }

  isValueInvalid(): boolean {
    return this.value === null || this.value === undefined || (this.options && this.options?.multiple ? !this.value.length : false);
  }


}
