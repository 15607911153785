import { ListFilter } from '../list-filter.interface';
import { FilterType } from '../filter-item.interface';

export class RadioButtonsFilter extends ListFilter {
  type = FilterType.RadioButtons;


  filterItem(valueItem: any): boolean {
    if (this.isValueInvalid()) {
      // filter is off;
      return true;
    }

    if (!(this.field in valueItem)) {
      console.warn(`${this.field} is not presented in the filtered item`);
      return false
    }

    if (this.options?.filterFn) {
      return this.options.filterFn(this.value, valueItem);
    } else {
      return this.value === valueItem[this.field];
    }
  }

  filter(values: any[]): any[] {
    if (this.isValueInvalid()) {
      // filter is off;
      return values;
    }

    return values.filter((item: any) => this.filterItem(item))
  }

  isValueInvalid(): boolean {
    return this.value === null || this.value === undefined;
  }


}
