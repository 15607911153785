import { Component, input, OnInit, ViewChild } from '@angular/core';
import { NgClass } from '@angular/common';

@Component({
  selector: 'phar-list-template',
  templateUrl: 'list-template.component.html',
  styleUrl: 'list-template.component.scss',
  standalone: true,
  imports: [
    NgClass
  ]
})

export class ListTemplateComponent {
  @ViewChild('listBody', { static: true }) listBody: HTMLDivElement;
  listContainerClasses = input([]);
}
