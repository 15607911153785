import { FilterType } from './filter-item.interface';
import { IListFilter, ListFilter } from './list-filter.interface';
import { StringFilter } from './filter-items/string-filter.class';
import { BehaviorSubject, Observable } from 'rxjs';
import { NumberFilter } from './filter-items/number-filter.class';
import { cloneDeep, isArray } from 'lodash-es';
import { DropdownFilter } from './filter-items/dropdown-filter.class';
import { DateRangeFilter } from './filter-items/date-range-filter.class';
import { AutoCompleterFilter } from './filter-items/auto-completer.filter';
import { BooleanFilter } from './filter-items/boolean-filter';
import { RadioButtonsFilter } from './filter-items/radio-buttons-filter';

export class ListFilterManager {

  private _filters: BehaviorSubject<ListFilter[]> = new BehaviorSubject<ListFilter[]>([])
  public filters$: Observable<ListFilter[]> = this._filters.asObservable();

  get filters(): ListFilter[] {
    return this._filters.value;
  }

  constructor(filters: ListFilter[] = [],) {
    if (filters && filters.length) {
      this._filters.next([...filters]);
    }
  }


  createFilter(data: { filter: IListFilter, mainFilter: boolean }): void {
    switch (data.filter.type) {
      case FilterType.String:
        this.appendFilter(new StringFilter(data.filter.field, data.filter.value, data.filter.label, data.filter.options), data.mainFilter);
        break;
      case FilterType.Number:
        this.appendFilter(new NumberFilter(data.filter.field, data.filter.value, data.filter.label, data.filter.options), data.mainFilter);
        break;
      case FilterType.Dropdown:
        this.appendFilter(new DropdownFilter(data.filter.field, data.filter.value, data.filter.label, data.filter.options), data.mainFilter);
        break;
      case FilterType.DateRange:
        this.appendFilter(new DateRangeFilter(data.filter.field, data.filter.value, data.filter.label, data.filter.options), data.mainFilter);
        break;
      case FilterType.AutoCompleter:
        this.appendFilter(new AutoCompleterFilter(data.filter.field, data.filter.value, data.filter.label, data.filter.options), data.mainFilter);
        break;
      case FilterType.Boolean:
        this.appendFilter(new BooleanFilter(data.filter.field, data.filter.value, data.filter.label, data.filter.options), data.mainFilter);
        break;
      case FilterType.RadioButtons:
        this.appendFilter(new RadioButtonsFilter(data.filter.field, data.filter.value, data.filter.label, data.filter.options), data.mainFilter);
        break;
    }

  }

  /**
   * This is the alternative filtering method which iterate first across the values and then each value
   * being checked by each filter. This method allow us to switch the condition ADN/OR. The default condition is AND
   * @param values
   */
  filterItems(values?: any[]): any[] {
    let filteredValues = values || []
    const filters: ListFilter[] = this._filters.getValue();
    return filteredValues.filter((valueItem: any) => {
      const filteringArray = [];
      filters.forEach((filter: ListFilter) => {
        // each class
        filteringArray.push(filter.filterItem(valueItem));
      });


      if (!filteringArray.length) {
        return valueItem;
      }

      // simulating AND logic
      // use .some to emulate OR
      return filteringArray.every(val => !!val);

    });
  }

  /**
   * this is the default method that iterate across the filters and pass filtered data to the next filter
   * @param values
   */
  filter(values?: any[]): any[] {

    let filteredValues = values || [];
    if (!filteredValues.length) {
      return filteredValues;
    }
    this._filters.getValue().forEach((filter: ListFilter) => {
      filteredValues = filter.filter(filteredValues);
    });
    return filteredValues
  }

  clearFilters(): void {
    this._filters.next([]);
  }


  private findFilterBy(field: string, value: any): number {
    return this._filters.value.findIndex(filter => field in filter && filter[field] === value);
  }


  updateFilter(filter: ListFilter | ListFilter[], field = 'id',): void {
    const cloned: ListFilter[] = cloneDeep(this._filters.getValue())
    if (isArray(filter)) {
      filter.forEach(filter => {
        const index = this.findFilterBy(field, filter[field]);
        if (index < 0) {
          return;
        }
        cloned[index].update(filter);
      });
    } else {
      const index = this.findFilterBy(field, filter[field]);
      if (index < 0) {
        return;
      }
      cloned[index].update(filter);
    }

    this._filters.next(cloned);
  }

  removeFilter(filter: ListFilter | ListFilter[]): void {
    const cloned: ListFilter[] = cloneDeep(this._filters.getValue());
    if (isArray(filter)) {
      const toDeleteIds: number[] = filter.map(item => item.id)
      this._filters.next(cloned.filter(item => !toDeleteIds.includes(item.id)))

    } else {
      this._filters.next(cloned.filter(f => f.id !== filter.id))

    }
  }

  private appendFilter(filter: ListFilter, mainFilter = false): void {
    const index = this.findFilterBy('field', filter.field)
    if (mainFilter && index > -1) {
      this._filters.getValue()[index] = filter;
      this._filters.next(this._filters.getValue());
      return;
    }
    this._filters.next([...this._filters.getValue(), filter]);
  }


}
