import { ListFilter } from '../list-filter.interface';
import { FilterType } from '../filter-item.interface';
import moment from 'moment';

export class DateRangeFilter extends ListFilter {
  type = FilterType.DateRange;
  value: { start: Date | null, end: Date | null } | null = null;


  filterItem(valueItem: any): boolean {
    if (this.isValueInvalid()) {
      // filter is off;
      return true;
    }

    if (!(this.field in valueItem)) {
      console.warn(`${this.field} is not presented in the filtered item`);
      return false
    }
    // //do the actual filtering
    const val = valueItem[this.field];
    if( this.options?.filterFn) {
      return this.options.filterFn(this.value, valueItem);
    } else {
      return moment(val).utc().isBetween(moment(this.value.start).utc(), moment(this.value.end).utc(), 'd', '[]')
    }
  }

  filter(values: any[]): any[] {
    if (this.isValueInvalid()) {
      // filter is off;
      return values;
    }

    return values.filter((item: any) => this.filterItem(item))
  }

  isValueInvalid() {
    return this.value === null || this.value === undefined || !this.value.start || !this.value.end
  }


}
