/* src/app/shared/list-template/list-template.component.scss */
.list-container {
  height: calc(100vh - (var(--main-header-height) + 10px));
  display: grid;
  grid-template-areas: "header" "content";
  grid-template-rows: max-content;
}
.list-container__header {
  grid-area: header;
}
.list-container__body {
  grid-area: content;
  overflow: auto;
}
/*# sourceMappingURL=list-template.component.css.map */
