import { FilterType } from './filter-item.interface';
import { Observable } from 'rxjs';

export class ListFilter implements IListFilter, IListFilterFn {
  type: FilterType
  id: number;

  constructor(public field: string, public value: any, public label: string, public options: IListFilterOptions = null) {
    this.id = new Date().getTime();
  }

  /**
   * This function will be overridden by the children that extends this class
   * Each child should override this function and return true/false
   * Each should contains own filter logic
   * This is an alternative filter fn that filter by item
   * @param value
   */
  filterItem(value: any): boolean {
    return !!value;
  }

  /**
   * This function will be overridden by the children that extends this class
   * Each child should override this function and result array of any
   * Each should contains own filter logic
   * This is an alternative filter fn that filter given array
   * @param values
   */
  filter(values: any[]): any[] {
    return values;
  }


  update(filter: ListFilter) {
    Object.keys(filter).forEach(key => {
      this[key] = filter[key];
    })
  }

  isValueInvalid(): boolean {
    return false;
  }

}

export interface IListFilterFn {

  filterItem(values: any): boolean;

  filter(values: any[]): any[];

  isValueInvalid(): boolean

  update(filter: ListFilter): void;
}


export interface IListFilter {
  id?: number;
  type: FilterType;
  field: any;
  value: any;
  label: string;
  options?: IListFilterOptions;
}

export interface IListFilterDisplayOption {
  type: FilterType;
  label: string;
  field: string;
  title: string;
  options?: IListFilterOptions;
  disabled?: boolean;

}

export interface IListFilterOptions {
  multiple?: boolean;
  autocomplete?: boolean;
  value?: any;
  data?: Observable<ListFilterDataItem[]>;
  filterFn?: (value: any, item: any) => boolean;
}

export type ListFilterDataItem =  { id: any, label: string };
