import { ListFilter } from '../list-filter.interface';
import { FilterType } from '../filter-item.interface';

export class StringFilter extends ListFilter {
  type = FilterType.String;


  filterItem(valueItem: any): boolean {
    if (this.value.trim() === '' || !(this.field in valueItem)) {
      return valueItem
    }

    if (this.options?.filterFn) {
      return this.options.filterFn(this.value, valueItem);
    } else {
      return valueItem[this.field].toLowerCase().includes(this.value.toLowerCase());
    }
  }

  filter(values: any[] = []): any[] {
    if (this.value === null || this.value === undefined || this.value.trim() === '') {
      return values;
    }
    return values.filter(item => this.filterItem(item));
  }

}
